<template>
  <div class="content">
    <div class="background"></div>
    <v-card class="login" flat>

      <v-card-text>
        <div>
            <v-img src="@/assets/logo.png" width="125"></v-img>
        </div>

        <div v-show="view == 'login'">
            Ingrese sus credenciales para acceder al área administrativa
        </div>

        <!-- login view -->
        <div v-show="view == 'login'">

            <div style="margin: 20px 0 0 0;">
                <v-alert text type="error" v-show="error.length > 0">{{ error }}</v-alert>
                <v-form v-model="valid" ref="form">
                    <v-text-field v-model="username" placeholder="Email o teléfono" dense :rules="usernameRules" required></v-text-field>
                    <v-text-field v-model="password" placeholder="Contraseña" :type="viewPassword ? 'text' : 'password'" 
                        :append-icon="viewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense 
                        @click:append="viewPassword = !viewPassword" :rules="passwordRules" required></v-text-field>
                    
                    <template v-if="requireNewPassword">
                        <span class="caption">Su password ha expirado. Por favor, entre uno nuevo.</span>
                        <v-text-field v-model="newPassword" :type="showNewPassword ? 'text' : 'password'" placeholder="New Password" 
                            :hide-details="newPasswordError.length == 0" required :rules="passwordRules" 
                            :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showNewPassword = !showNewPassword"></v-text-field>
                    </template>
                </v-form>

                <a href="#" class="forgotPassword" @click.prevent="view = 'forgot'">¿Olvidaste tu contraseña?</a>
            </div>

            <div>
                <v-btn depressed rounded block dark color="primary" :loading="loading" @click="login" style="text-transform:none;margin: 30px 0;">Iniciar sesión</v-btn>
            </div>
        </div>

        <!-- forgot password view -->
        <div v-show="view == 'forgot'">
            <div style="margin: 20px 0 0 0;">
                <div class="my-4">Entre el correo electrónico de su cuenta.</div>
                <v-form ref="recoverForm">
                    <div class="py-5"></div>

                    <v-text-field v-model="username" placeholder="Correo electrónico" :rules="emailRules" :error-messages="usernameError"></v-text-field>

                    <div class="py-5"></div>
                </v-form>

                <v-btn depressed rounded block dark color="primary" style="text-transform:none;margin: 30px 0 0 0;" @click="forgotPasswordClick" :loading="loading">Recuperar</v-btn>
                    <div class="mt-5 text-center">
                    <a href="#" style="color:blue;text-decoration:none;" @click.prevent="view = 'login'">&#60;-- Volver atrás</a>
                </div>
            </div>
        </div>

        <!-- change password view -->
        <div v-show="view == 'change'">
            <div style="margin: 20px 0 0 0;">
                <div class="my-4">Se ha enviado un código a su correco electrónico. Por favor, entre el código en campo de abajo y la nueva contraseña para su cuenta.</div>
                <v-form ref="changeForm">
                    <v-text-field v-model="code" placeholder="Código de confirmación" dense :rules="[v => !!v || 'el código es requerido']" 
                        :error-messages="codeError"></v-text-field>
                    <v-text-field v-model="password" placeholder="Contraseña" :type="viewPassword ? 'text' : 'password'" :rules="passwordRules" :error-messages="passwordError"
                        :append-icon="viewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense @click:append="viewPassword = !viewPassword"></v-text-field>
                    <v-text-field v-model="confirmPassword" placeholder="Confirmación de contraseña" :type="viewConfirmPwd ? 'text' : 'password'" :rules="confirmPasswordRules" :error-messages="confirmPasswordError"
                        :append-icon="viewConfirmPwd ? 'mdi-eye-off-outline' : 'mdi-eye-outline'" dense @click:append="viewConfirmPwd = !viewConfirmPwd"></v-text-field>
                </v-form>

                <v-btn depressed rounded block dark color="primary" style="text-transform:none;margin: 30px 0 0 0;" @click="changePasswordClick" :loading="loading">Cambiar contraseña</v-btn>
                <div class="mt-5 text-center">
                    <a href="#" style="color:blue;text-decoration:none;" @click.prevent="view = 'login'">&#60;-- Volver atrás</a>
                </div>
            </div>
        </div>
      </v-card-text>

    </v-card>

    <div class="copyrights">© Petu Power {{ year }}. All rights reserved.</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { _st } from '../../softech';
export default {
    data() {
        return {
            valid               : true,
            username            : '',
            usernameError       : '',
            usernameRules       : [
                v => !!v || 'el username es requerido',
            ],
            password            : '',
            passwordError       : '',
            passwordRules       : [
                v => !!v || 'el password es requerido',
            ],
            newPassword         : '',
            newPasswordError    : '',
            emailRules          : [
                v => !!v || 'el email es requerido',
                v => !!_st.isEmail( v ) || 'el email tiene que ser válido'
            ],
            confirmPassword     : '',
            confirmPasswordError: '',
            confirmPasswordRules: [
                v => !!v || 'el password es requerido',
                v => !!(v == this.password) || 'no es igual que el password'
            ],
            code                : '',
            codeError           : '',

            error               : '',
            view                : 'login',

            loading             : false,
            viewPassword        : false,
            viewConfirmPwd      : false,
            showNewPassword     : false,
            requireNewPassword  : false,
        }
    },
    computed: {
        ...mapGetters({
            user            : 'auth/user'
        }),
        year() {
            return (new Date()).getFullYear();
        }
    },
    created() {
        if( !_st.isNUE( this.user ) ) 
            this.$router.push( '/secure/dashboard' );
    },
    methods: {
        ...mapActions({
            loginVue                : 'auth/login',
            completeNewPasswordVue  : 'auth/completeNewPassword',
            forgotPassword          : 'auth/forgotPassword',
            submitForgotPassword    : 'auth/submitForgotPassword',
        }),
        async login() {
            this.error = '';
            if( !this.$refs.form.validate() ) {
                this.error = 'El email y el password son requeridos.';
                return;
            }

            try {
                this.loading = true;
                if( this.requireNewPassword ) {
                    // change password while loggin in for the first time
                    await this.completeNewPasswordVue({
                        username: this.username,
                        oldPassword: this.password,
                        newPassword: this.newPassword
                    });
                }
                else {
                    // login regularly
                    await this.loginVue({
                        username: this.username,
                        password: this.password
                    })
                }

                if( !_st.isNU( this.user ) && this.user.challengeName === 'NEW_PASSWORD_REQUIRED' ) {
                    this.requireNewPassword = true;
                    this.loading = false;
                    return;
                }

                this.$router.push('/secure/dashboard');
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.error = error.message;
            }
        },
        async forgotPasswordClick() {
            try {
                this.usernameError = '';
                if( !this.$refs.recoverForm.validate() ) 
                    return;

                this.loading = true;
                await this.forgotPassword( this.username );

                this.loading = false;
                this.view = 'change';
            }
            catch(error) {
                console.log(error);
                this.loading = false;

                if( error.code == 'UserNotFoundException' )
                    this.usernameError = 'No existe ninguna cuenta con este email';
            }
        },
        async changePasswordClick() {
            this.usernameError = '';
            this.error = '';
            this.passwordError = ''; 
            this.confirmPasswordError = '';
            this.codeError = '';

            if( !this.$refs.changeForm.validate() ) 
                return;
            if( this.password != this.confirmPassword) {
                this.confirmPasswordError = 'no es igual a la contraseña';
                return;
            }

            this.loading = true;
            try {
                let change = await this.submitForgotPassword({ 
                    email: this.username, 
                    code: this.code, 
                    newPassword: this.password
                });
                console.log(change);

                this.loading = false;
                this.view = 'login';
                await this.login();
            }
            catch(error) {
                console.log(error);
                this.loading = false;

                if( error.code == 'CodeMismatchException' )
                this.codeError = 'Código inválido';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
/* color1: #ee3a8c */
/* color2: #f68926 */
.content {
  height: 100vh;
  margin: -12px;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.background {
  height: 70vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(238,58,140);
  background: linear-gradient(187deg, rgba(238,58,140,1) 0%, rgba(246,137,38,1) 100%);
}
.login {
  border-radius: 25px !important;
  height: 70vh;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
.login .v-card__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px 30px; 
}

.forgotPassword {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  display: inline-block;
  width: 100%;
}

.copyrights {
    color: green;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    margin-left: -25px;
    font-size: 12px;
    text-align: center;
}

</style>
